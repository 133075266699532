<template>
  <div class="home-contrain contrain-pub">
    <header-back name="号卡详情"> </header-back>
    <section class="cen top44">
      <div class="cen-img">
        <img
          v-for="(item, index) in imgList"
          :key="index"
          :src="item"
        />
      </div>
      <div class="cen-sm">
        <img
          src="../../assets/image/suom.png"
          alt=""
          v-if="show1"
        />
        <div
          class="cen-sm-ts"
          v-if="addshow"
          @click="switchShow"
        >
          温馨提醒：
          个人擅自买卖实名电话卡属违法行为，请勿将号卡租借、贩卖给他人，如被他人利用发生非法违规行为，您将承担相应法律责任，请您确保规范使用您的号码。查看详情>>>
        </div>
        <div
          class="cen-sm-ts"
          v-else
          @click="switchShow"
        >点击收起▲</div>
        <div
          class="cen-sm-red"
          @click="tanChaung()"
        >其他说明及温馨提示</div>
      </div>
      <!-- <ul class="home-ul">
        <li
          v-for="(item,index) in list"
          :key='index'
        >
          <div
            class="ul-item"
            @click="itemClick(item)"
          >
            <img :src="item.img">
            <div class="item-tit">
              <span>官方首发</span> {{item.name}}
            </div>
            <div class="item-tit2">
              <div class="item-tit2-l"><span class="span1">免费</span> <span class="span2">包邮</span></div>
              <div class="item-tit2-r">
                <van-button
                  type="primary"
                  :color="variables.textColor"
                  round
                  size="small"
                >立即领取</van-button>
              </div>
            </div>
          </div>
        </li>
      </ul> -->
    </section>
  </div>
</template>
<script>
import { Dialog } from "vant";
import HeaderBack from "../../components/header-back";
import variables from "@/styles/variables.scss";
export default {
  components: { HeaderBack },

  data() {
    return {
      show1: false,
      addshow: true,
      list: [
        {
          img: require("../../assets/image/yidong.jpg"),
          name: "移动  可选号码归属地 月租29元 30G专享流量",
          path: "https://h5.liulianglf.cn/h5/index.html?id=4438665264155468872&pid=48646",
        },
        {
          img: require("../../assets/image/liantong.jpg"),
          name: "联通  可选号码归属地 月租29元 30G专享流量",
          path: "https://h5.liulianglf.cn/h5/index.html?id=5581302159523492688&pid=48647",
        },
      ],
      imgList: [
        require("../../assets/image/yidong.jpg"),
        require("../../assets/image/yidong2.jpg"),
        require("../../assets/image/yidong3.jpg"),
        require("../../assets/image/yidong4.jpg"),
        require("../../assets/image/yidong5.jpg"),
      ],
      imgData: {
        48646: [
          require("../../assets/image/yidong.jpg"),
          require("../../assets/image/yidong2.jpg"),
          require("../../assets/image/yidong3.jpg"),
          require("../../assets/image/yidong4.jpg"),
          require("../../assets/image/yidong5.jpg"),
        ],
        48647: [
          // require("../../assets/image/liantong1.jpg"),
          require("../../assets/image/t1.jpg"),
          require("../../assets/image/t2.jpg"),
          // require("../../assets/image/liantong4.png"),
        ],
        48648: [
          require("../../assets/image/t11.jpg"),
          require("../../assets/image/t22.jpg"),
          require("../../assets/image/t33.jpg"),
        ],
      },
      overlayShow: false,
    };
  },
  computed: {
    variables() {
      return variables;
    },
  },
  created() {
    if (this.$route.query.id) {
      let pid = this.$route.query.id;
      this.imgList = this.imgData[pid];
    }
  },
  methods: {
    onDetail() {
      this.$router.push("/Order");
    },
    itemClick(item) {
      window.location.href = item.path;
    },
    switchShow() {
      this.addshow = !this.addshow;
      this.show1 = !this.show1;
    },
    tanChaung() {
      Dialog.alert({
        show: true,
        messageAlign: "left",
        message: `<p style="margin:0">1、宝藏卡29元档新入网用户免收首月套餐费（激活当日起至当月底），套餐内专属流量、亲情网优惠全量提供，次月起全额收取29元/月。</p>
        <p style="margin:0">2、宝藏卡29元档套外通用流量5元1G，当月有效。专属流量均不可结转、不可转赠、不可共享。每月总流量（含通用流量、专属流量）使用上限为200GB，次月初自动恢复。</p>
        <p style="margin:0">3、宝藏卡29元档套外语音资费0.1元/分钟，免费接听；国内点对点短信0.1元/条，包含来电显示。</p>
        <p style="margin:0">4、宝藏卡29元档套餐内包含30GB专属流量月包，专属流量范围包括中国移动精品APP及阿里系、百度系、抖音系、网易游戏、腾讯系、芒果TV、快手、喜马拉雅、新浪微博、VIVO等超过100款第三方热门APP。具体支持专属流量的APP清单随中国移动业务合作情况或将发生变动，以订购页面具体内容为准。</p>
        <p style="margin:0">5、建议使用专属流量包前将APP升级至最新版本，请在WIFI功能关闭下使用专属流量功能，避免WIFI和移动网络频繁切换导致专属流量服务启动失败。客户端不包含在“专属流量”内的使用范围，特别说明如下：</p>
        <p style="margin:0">① 客户端启动、登录；客户端内的插播广告（图片、文字及视频），弹幕，第三方的广告或直播、主播类视频，在线观看和下载缓存的第三方视频等。</p>
        <p style="margin:0">②手机号码处于国际或港澳台地区漫游状态下产生的流量。</p>
        <p style="margin:0">③利用手机设置代理服务器或VPN等方式，访问APP所产生的流量。</p>
        <p style="margin:0">④用无线上网卡、平板电脑、CPE、MIFI等非手机设备使用产生的流量。</p>
        <p style="margin:0">⑤使用CMWAP接入点方式，访问视频APP所产生的流量。</p>
        <p style="margin:0">⑥当宝藏卡号码作为热点使用时，网络不稳定可能会出现专属流量和通用流量交错使用的情况。</p>
        <p style="margin:0">6、宝藏卡内包含全国亲情网3个亲情号码（含主号4人）国内畅聊，您可在中国移动APP开通全国亲情网（统付版）并添加亲情号码。</p>
        <p style="margin:0">7、上述资费适用范围不含港澳台地区。</p>
        <p style="margin:0">8、同一身份证在30天内能够购买一个号码，号卡仅寄送本省，需本人持身份证当面激活。因国家政策原因部分地址可能无法配送。</p>
        <p style="margin:0">9、为了保障通信秩序，对于申请人信息、年龄、IP、联系方式、联系地址相近或异常等疑似重复购买或异常订购的订单，可能不予发货。</p>
        <p style="margin:0">10、根据国家实名制相关规定，请保证所购号卡为本人使用，不得转售、倒卖号卡。</p>
        <p style="margin:0">11、广东、山西为灵活账期省份，首月免月费优惠参考客户实际账期规则。</p>
        <p style="margin:0">12、其它未尽事宜，详询10086。</p>
        <p style="margin:0"></p>
        <p style="margin:0"></p>
        <p style="margin:0"></p>
        <p style="margin:0">专属流量使用APP清单</p>
        <p style="margin:0">哔哩哔哩、快手（含快手极速版）、芒果TV、新浪微博、网易云音乐、喜马拉雅</p>
        <p style="margin:0">腾讯系：</p>
        <p style="margin:0">手机QQ、腾讯视频、和平精英、酷狗音乐、酷我音乐、手游加速器</p>
        <p style="margin:0">阿里系：</p>
        <p style="margin:0">优酷视频、手机淘宝、点淘、手机天猫、支付宝、钉钉、口碑、闲鱼、聚划算、蚂蚁财富</p>
        <p style="margin:0">百度系：</p>
        <p style="margin:0">百度、爱奇艺、百度地图、 手机助手、百度输入法、好看视频、百度贴吧、百度文库、百度翻译、百度糯米、百度百科、秒懂百科、百度知道、全民小视频。爱奇艺包含爱奇艺、爱奇艺随刻、爱奇艺泡泡、爱奇艺奇巴布、爱奇艺知识、爱奇艺漫画、奇秀、电视果</p>
        <p style="margin:0">抖音系：</p>
        <p style="margin:0">抖音、抖音极速版、今日头条、西瓜视频、 抖音火山版、皮皮虾、懂车帝、FaceU激萌、轻颜相机、飞书、飞聊、多闪、图虫、gogokid、时光相册、半次元、海豚股票、海豚财富、好好学习</p>
        <p style="margin:0">网易游戏：</p>
        <p style="margin:0">一梦江湖、大话西游、决战平安京、梦幻西游、倩女幽魂、阴阳师、网易大神、大唐无双、第五人格、非人学园、孤岛先锋、光明大陆、花与剑、荒野行动、流星群侠传、率土之滨、梦幻西游3D、秘境对决、明日之后、权力与纷争、神都夜行录、实况足球、坦克世界闪击战、天下、永远的七日之都、战国志、镇魔曲、三国如龙传、量子特攻</p>
        <p style="margin:0">VIVO系：</p>
        <p style="margin:0">vivo应用商店、vivo游戏中心、i音乐、i主题、vivo官网、vivo商城</p>
        <p style="margin:0">中国移动精品应用：</p>
        <p style="margin:0">咪咕视频、咪咕音乐、咪咕阅读、咪咕快游、MM应用商城、和彩云</p>
        <p style="margin:0">专属流量适用范围：</p>
        <p style="margin:0">建议使用定向专属流量包前将APP升级至最新版本，请在WIFI功能关闭下使用专属流量功能，避免WIFI和移动网络频繁切换导致专属流量服务启动失败。客户端不包含在“定向流量”内的适用范围，特别说明如下：</p>
        <p style="margin:0">① 客户端启动、登录；客户端内的插播广告（图片、文字及视频），弹幕，第三方的广告或直播、主播类视频，在线观看和下载缓存的第三方视频等。</p>
        <p style="margin:0">② 手机号码处于国际或港澳台地区漫游状态下产生的流量。</p>
        <p style="margin:0">③ 利用手机设置代理服务器或VPN等方式，访问APP所产生的流量。</p>
        <p style="margin:0">④ 用无线上网卡、平板电脑、CPE、MIFI等非手机设备使用产生的流量。</p>
        <p style="margin:0">⑤ 手机作为热点使用产生的流量。</p>
        <p style="margin:0">⑥ 使用CMWAP接入点方式，访问视频APP所产生的流量。</p>

        <p style="margin:0">2、团队在推广的时候，必须遵循用户知情且同意兑换的原则，不允许多扣，错扣；若发现实际兑换的与承诺用户的产品不符，则每单罚款500元。请知悉传达！！！</p>`,
      }).then(() => {
        // on close
      });
    },
  },

  destroyed() {},
};
</script>
<style lang="scss" scoped>
@import "~@/styles/common.scss";

.cen {
  margin-top: 20px;
}
.cen-img {
  text-align: center;
  img {
    width: 100%;
  }
}
.home-img {
  height: 160px;
  width: 100%;
  & > img {
    height: 100%;
    width: 100%;
  }
}
.service {
  position: absolute;
  left: 0;
  bottom: 25%;
  img {
    width: 55px;
    height: 52px;
  }
}
.home-tit {
  padding: 12px 16px;
  display: flex;
  font-size: 12px;
  color: #1c1c1c;
  align-items: center;

  .home-tit-l {
    flex: 1;
    span {
      color: #d54452;
      margin: 0 6px;
    }
    img {
      height: 15px;
      width: 91px;
      vertical-align: bottom;
    }
  }
  .home-tit-r {
    width: 130px;
    text-align: right;
  }
}
.home-ul {
  overflow: hidden;
  padding: 0 8px;
  font-size: 12px;
  li {
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 0 8px;
  }
  .ul-item {
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 10px;
    & > img {
      width: 100%;
      height: 140px;
    }
    .item-tit {
      padding: 10px 10px 0 10px;
      line-height: 24px;
      color: #1c1c1c;
      & > span {
        padding: 2px 6px;
        font-size: 10px;
        color: #fff;
        background: linear-gradient(90deg, #f80d0d 0%, #ff4200 100%);
        border-radius: 4px;
      }
    }
    .item-tit2 {
      padding: 0 10px 10px 10px;
      display: flex;
      & > div {
        flex: 1;
      }
      .item-tit2-l {
        .span1 {
          font-size: 16px;
          letter-spacing: 1px;
          font-weight: 500;
          color: #f91819;
        }
        .span2 {
          font-size: 12px;
          padding: 0px 1px;
          color: #f91819;
          border: 1px solid #f91819;
          border-radius: 2px;
        }
      }
      .item-tit2-r {
        text-align: right;
        color: #ababab;
        font-size: 10px;
        padding-top: 6px;
      }
    }
  }
}
.cen-sm {
  margin-top: -5px;
  background-color: #a7bbf7b8;
  text-align: center;
  height: 150px;
  position: relative;
  img {
    width: 100%;
  }
  .cen-sm-ts {
    font-size: 16px;
  }
  .cen-sm-red {
    color: red;
    border: 1px solid red;
    width: 50%;
    font-size: 20px;
    margin-top: 10px;
    margin-left: 25%;
  }
}
</style>
